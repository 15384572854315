export const ROUTES = {
  HOME: '/',
  LOGIN: '/_auth/login',
  LOGOUT: '/_auth/logout',
  REGISTER: '/_auth/register',
  SYSTEMS: '/_dashboard/systems/',
  SYSTEM: '/_dashboard/systems/$systemSymbol/',
  WAYPOINT: '/_dashboard/systems/$systemSymbol/waypoint/$waypointSymbol',
  FLEET: '/_dashboard/_authenticated/fleet/',
  SHIP: '/_dashboard/_authenticated/fleet/$shipSymbol',
  SHIP_MARKET: '/_dashboard/_authenticated/fleet/$shipSymbol/_ship_overlay/market',
  CONTRACTS: '/_dashboard/_authenticated/contracts',
  SURVEYS: '/_dashboard/_authenticated/surveys',
  LEADERBOARD: '/_dashboard/leaderboard',
  AGENTS: '/_dashboard/agents/',
  AGENT: '/_dashboard/agents/$agentSymbol',
} as const satisfies Record<string, string>
